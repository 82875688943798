import React from "react";

// Components
import { fadeIn } from "../Functions/GlobalAnimations";
import Tab01 from "../Components/Tab/Tab01";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import { m } from "framer-motion";
import { Link as ScrollTo } from "react-scroll";

// Partials
import DefaultFooter from "./Partial/DefaultFooter";
import ServicesSection from "./Partial/ServicesSection";
import DarkHeader from "./Partial/DarkHeader";

// Data

const TabData = [
  {
    tabTitle: "01. EQUIPEMENTS",
    subtitle: "Équipements de Levage Innovants",
    title: "Des solutions matérielles robustes et innovantes",
    img: "/assets/img/steellift-energy-group-solutions-equipements.jpg",
    content:
      "Nous proposons des solutions robustes et innovantes pour répondre à vos besoins de levage industriel. Notre gamme d'équipements, avec une large variété d'équipements, assure des opérations sécurisées, efficaces et précises. Faites confiance à notre expertise et à nos technologies de pointe pour garantir la réussite de vos projets.",
    buttonTitle: "En Savoir Plus",
    buttonLink: "/levage/nos-equipements",
  },
  {
    tabTitle: "02. SERVICES",
    subtitle: "Assistance et Maintenance de Levage",
    title: "Assistance technique et maintenance préventive",
    img: "/assets/img/steellift-energy-group-solutions-services.jpg",
    content:
      "Chez Steel Lift Energy Group, nous offrons une assistance technique de pointe et des services de maintenance préventive pour assurer la performance optimale de vos équipements. Nos experts résolvent rapidement les problèmes techniques et nos programmes de maintenance anticipent les pannes pour minimiser les temps d'arrêt.",
    buttonTitle: "En Savoir Plus",
    buttonLink: "/levage/nos-services",
  },
  {
    tabTitle: "03. ETUDES & INGENIERIE",
    subtitle: "Analyse et Conception de Solutions",
    title: "Analyse détaillée et conception sur mesure",
    img: "/assets/img/steellift-energy-group-solutions-ingenierie.jpg",
    content:
      "Nous offrons une analyse détaillée de vos besoins et une conception sur mesure de solutions adaptées. Notre équipe d'experts utilise des technologies avancées pour comprendre les spécificités de chaque projet et développer des solutions personnalisées qui répondent parfaitement à vos exigences. Grâce à notre approche rigoureuse et notre expertise, nous garantissons des résultats optimaux et une satisfaction totale.",
    buttonTitle: "En Savoir Plus",
    buttonLink: "/etudes-et-ingenierie",
  },
];

const OurSolutionsPage = (props) => {
  return (
    <div style={props.style}>
      <DarkHeader />

      {/* Parallax Scrolling Start */}
      <div className="h-[660px] lg:h-[580px] md:h-[550px] sm:h-[500px] xs:h-[380px] flex items-center overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 w-full h-[100vh]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(/assets/img/steellift-energy-group-nos-solutions-de-levage.jpg)`,
          }}
        ></Parallax>
        <div className="absolute h-full w-full opacity-50 top-0 left-0 bg-darkgray"></div>
        <Container>
          <Row className="items-center justify-center">
            <Col md={8} xl={6} lg={7} sm={9} className="relative text-center">
              <h1 className="inline-block text-white opacity-60 mb-[20px] text-xmd leading-[20px] -tracking-[.5px] font-serif">
                Notre expertise au service de vos projets de levage les plus
                ambitieux.
              </h1>
              <h2 className="font-serif text-white -tracking-[1px] text-[3.9rem] font-medium mb-0 sm:-tracking-[1px]">
                Solutions Innovantes de Levage
              </h2>
            </Col>
            <ScrollTo
              to="about"
              offset={0}
              delay={0}
              spy={true}
              smooth={true}
              duration={800}
              className="absolute bottom-[50px] left-1/2 w-auto inline-block p-0 -translate-x-1/2 sm:bottom-7 xs:bottom-[4.5rem] cursor-pointer"
            >
              <i className="ti-arrow-down text-lg leading-[1] text-white bg-[#000000b3] p-[15px] xs:p-[10px] rounded-full flex justify-center items-center"></i>
            </ScrollTo>
          </Row>
        </Container>
      </div>
      {/* Parallax Scrolling End */}

      {/* Section Start */}
      <section className="py-[130px] overflow-hidden lg:py-[90px] sm:py-[75px] xs:py-[50px]">
        <Container>
          <Row className="items-center justify-center">
            <Col lg={5} md={9} className="mb-[10px] md:mb-28">
              <span className="font-serif mb-[20px] text-gradient bg-gradient-to-r from-[#FFC60B] via-orange-400 to-[#231F20] inline-block uppercase font-medium tracking-[1px]">
                Expertise en Levage Industriel
              </span>
              <h4 className="font-serif font-semibold text-darkgray w-[95%]">
                Votre partenaire en levage industriel
              </h4>
              <p className="w-[80%] mb-[25px] lg:w-[95%]">
                Chez <b>Steel Lift Energy Group</b>, nous sommes spécialisés
                dans le levage industriel, offrant des solutions sur mesure pour
                divers secteurs. Grâce à notre parc d'équipements modernes,
                incluant palans électriques et ponts roulants, semi portique …
                et à la compétence de notre équipe, nous réalisons des
                opérations sécurisées et précises.
              </p>
              <p className="w-[80%] mb-[25px] lg:w-[95%]">
                Que ce soit pour déplacer des charges lourdes ou installer des
                structures complexes, faites confiance à notre expertise pour
                vos projets de <b>levage industriel</b>.
              </p>
            </Col>
            <Col
              lg={7}
              md={9}
              xs={12}
              className="px-[55px] md:pr-[50px] md:pl-[5px]"
            >
              <m.figure className="image-back-offset-shadow w-100">
                <m.img
                  className="rounded-[6px] w-auto h-auto"
                  src="/assets/img/steellift-energy-group-nos-solutions-levage-industriel.jpg"
                  alt="our-services"
                  width="572.5"
                  height="436.53"
                  initial={{ opacity: 0, x: "0", y: "0" }}
                  whileInView={{ opacity: 1, x: "10px", y: "-20px" }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.4, easing: "linear" }}
                />
                <m.span
                  className="rounded-[6px]"
                  {...{ ...fadeIn, animate: { x: "35px", y: "5px" } }}
                ></m.span>
              </m.figure>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <m.section
        className="bg-lightgray py-[130px] lg:py-[95px] md:py-[75px] sm:py-[50px] overflow-hidden"
        {...fadeIn}
      >
        <Container>
          <Row className="justify-center">
            <Col lg={11}>
              <Tab01 data={TabData} />
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Parallax Scrolling Start */}
      <div className="h-[600px] md:h-[450px] sm:h-[350px] bg-top overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 md:-top-[30px] w-full h-[100vh]"
          translateY={[-50, 50]}
          style={{
            backgroundImage: `url(/assets/img/steellift-energy-group-solutions-parallax.jpg)`,
          }}
        ></Parallax>
      </div>
      {/* Parallax Scrolling End */}

      {/* Section Start */}
      <section className="py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <ServicesSection />
      </section>
      {/* Section End */}

      {/* Footer Section */}
      <DefaultFooter />
      {/* Footer End */}
    </div>
  );
};

export default OurSolutionsPage;

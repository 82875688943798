import React from "react";

// Components
import { Header, HeaderNav, Menu } from "../Components/Header/Header";
import { fadeIn } from "../Functions/GlobalAnimations";
import Buttons from "../Components/Button/Buttons";
import Clients from "../Components/Clients/Clients";
import FancyTextBox from "../Components/FancyTextBox/FancyTextBox";
import CustomModal from "../Components/CustomModal";
import SideButtons from "../Components/SideButtons";

import Services from "../Components/Services/Services";

// Libraries
import { Link } from "react-router-dom";
import { Col, Container, Row, Navbar } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import { m } from "framer-motion";
import { Link as ScrollTo } from "react-scroll";

// Partials
import DefaultFooter from "./Partial/DefaultFooter";

// Data
import { ClientData01 } from "../Components/Clients/ClientsData";
import DarkHeader from "./Partial/DarkHeader";

const fancyTextBox = [
  {
    icon: {
      text: "01",
      class: "text-gradient bg-gradient-to-r from-[#ffc60b] to-[#231f20]",
    },
    title: "Innovation",
    description: "Utilisation des technologies les plus récentes.",
  },
  {
    icon: {
      text: "02",
      class: "text-gradient bg-gradient-to-r from-[#ffc60b] to-[#231f20]",
    },
    title: "Excellence",
    description: "Prestations de haute qualité et normes strictes.",
  },
  {
    icon: {
      text: "03",
      class: "text-gradient bg-gradient-to-r from-[#ffc60b] to-[#231f20]",
    },
    title: "Sécurité",
    description: "Priorité à la sécurité avec des pratiques rigoureuses.",
  },
  {
    icon: {
      text: "04",
      class: "text-gradient bg-gradient-to-r from-[#ffc60b] to-[#231f20]",
    },
    title: "Fiabilité",
    description: "Solutions durables et fiables grâce à notre expertise.",
  },
];

const serviceData = [
  {
    img: "/assets/img/steellift-energy-group-a-propos-nos-solutions.jpg",
    icon: "ti-arrow-right",
    title: "Solution de levage",
    content:
      "Offrir des solutions de levage adaptées à divers environnements et applications industrielles.",
    linkTitle: "En savoir plus",
    link: "/levage",
  },
  {
    img: "/assets/img/steellift-energy-group-a-propos-nos-prestations.jpg",
    icon: "ti-arrow-right",
    title: "Prestations industrielles",
    content:
      "Fournir des services industriels intégrés et personnalisés pour améliorer l'efficacité de vos opérations.",
    linkTitle: "En savoir plus",
    link: "/nos-prestations-industrielles",
  },
  {
    img: "/assets/img/steellift-energy-group-a-propos-ingenierie.jpg",
    icon: "ti-arrow-right",
    title: "Etudes et ingénierie",
    content:
      "Offrir des services d'ingénierie et d'études techniques pour optimiser vos projets industriels.",
    linkTitle: "En savoir plus",
    link: "/etudes-et-ingenierie",
  },
];

const AboutUsPage = (props) => {
  return (
    <div style={props.style}>
      <DarkHeader />

      {/* Parallax Scrolling Start */}
      <div className="h-[660px] lg:h-[580px] md:h-[550px] sm:h-[500px] xs:h-[380px] flex items-center overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 w-full h-[100vh]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(/assets/img/steellift-energy-group-a-propos.jpg)`,
          }}
        ></Parallax>
        <div className="absolute h-full w-full opacity-50 top-0 left-0 bg-darkgray"></div>
        <Container>
          <Row className="items-center justify-center">
            <Col md={8} xl={6} lg={7} sm={9} className="relative text-center">
              <h1 className="inline-block text-white opacity-60 mb-[20px] text-xmd leading-[20px] -tracking-[.5px] font-serif">
                A propos de Steel Lift Energy Group
              </h1>
              <h2 className="font-serif text-white -tracking-[1px] text-[3.9rem] font-medium mb-0 sm:-tracking-[1px]">
                Construire l'avenir en acier
              </h2>
            </Col>
            <ScrollTo
              to="about"
              offset={0}
              delay={0}
              spy={true}
              smooth={true}
              duration={800}
              className="absolute bottom-[50px] left-1/2 w-auto inline-block p-0 -translate-x-1/2 sm:bottom-7 xs:bottom-[4.5rem] cursor-pointer"
            >
              <i className="ti-arrow-down text-lg leading-[1] text-white bg-[#000000b3] p-[15px] xs:p-[10px] rounded-full flex justify-center items-center"></i>
            </ScrollTo>
          </Row>
        </Container>
      </div>
      {/* Parallax Scrolling End */}

      {/* Section Description Start */}
      <section className="bg-lightgray py-[130px] lg:py-[95px] md:py-[70px] sm:py-[50px] relative overflow-visible">
        <Container>
          <Row className="items-center">
            <m.div
              className="col-lg-6 relative mt-[70px] lg:mt-[30px] md:mb-[50px]"
              {...fadeIn}
            >
              <div className="relative">
                <Parallax
                  className="lg-no-parallax w-[70%] rounded-[6px] lg:relative lg:!top-[-20px]"
                  speed={0}
                >
                  <div className="absolute top-0 left-0 w-full h-full rounded-[6px] opacity-50 bg-gradient-to-r from-yellow-500 to-gray-900"></div>
                  <img
                    loading="lazy"
                    src="/assets/img/steellift-energy-group-a-propos-construction-metallique.jpg"
                    className="rounded-[6px] w-full"
                    width="394.8"
                    height="466.34"
                    alt="our-process"
                  />
                </Parallax>
                <Parallax
                  className="lg-no-parallax flex rounded-[6px] justify-center items-center w-[70%] bg-no-repeat absolute bottom-0 right-[15px] lg:!top-[20px] lg:ml-auto"
                  speed={20}
                >
                  <img
                    loading="lazy"
                    src="/assets/img/steellift-energy-group-a-propos-solutions-levage.jpg"
                    className="rounded-[6px]"
                    width="394.8"
                    height="466.34"
                    alt="our-process"
                  />
                </Parallax>
              </div>
            </m.div>
            <m.div
              className="col-lg-5 offset-lg-1"
              {...{ ...fadeIn, transition: { delay: 0.5 } }}
            >
              <div className="font-serif text-xmd font-medium mb-[30px]">
                <span className="w-[50px] h-[1px] bg-[#231F20] inline-block align-middle mr-[20px]"></span>
                <span className="text-gradient bg-gradient-to-r from-[#FFC60B] via-orange-400 to-[#231F20] inline-block">
                  Steel Lift Energy Group
                </span>
              </div>
              <h5 className="font-serif text-darkgray font-medium mb-[30px] w-full">
                SPÉCIALISTE EN CONSTRUCTION MÉTALLIQUE
              </h5>
              <p className="w-[95%] mb-[35px]">
                Est une entreprise spécialisée dans la construction métallique,
                met au service de ses clients sa qualité de fabrication et son
                expertise dans la réalisation des ouvrages grâce à son personnel
                ferme de succès et ayant un savoir-faire combiné par
                l'expérience profonde dans ce secteur. Steellift Energy Group a
                toujours connu une importante évolution et a investi d'une façon
                régulière en matériel afin que la production reste innovante et
                répond aux exigences du marché marocain et africain.{" "}
              </p>
              <p className="w-[95%] mb-[35px]">
                <b>Steel Lift Energy Group</b> conçoit, fabrique et installe des
                solutions industrielles pour les réseaux de distribution
                d'énergie.
              </p>
            </m.div>
          </Row>
        </Container>
      </section>
      {/* Section Description End */}

      {/* Section "Nos valeurs" Start */}
      <section className="py-[130px] lg:py-[95px] md:py-[70px] sm:py-[50px] our-story-fancy-text-box">
        <Container>
          <Row className="text-center justify-center">
            <Col md={6} className="col-12 text-center mb-[4.5rem]">
              <span className="font-serif mb-[10px] block uppercase text-md font-medium">
                Les Piliers de Notre Réussite
              </span>
              <h5 className="font-serif text-darkgray font-medium mb-[25px] -tracking-[1px] xs:mb-[15px]">
                Nos Valeurs, Notre Force
              </h5>
            </Col>
          </Row>
          <FancyTextBox
            grid="row-cols-1 row-cols-lg-2 gap-y-10 lg:gap-y-[30px] xs:gap-y-[15px]"
            className="col-12 col-lg-6 col-md-9 col-sm-10"
            theme="fancy-text-box-03"
            data={fancyTextBox}
            animation={fadeIn}
          />
        </Container>
      </section>
      {/* Section "Nos valeurs" End */}

      {/* Section Notre activités Start */}
      <section className="py-[130px] lg:py-[95px] md:py-[70px] sm:py-[50px]py-[130px] sm:py-[50px] bg-lightgray">
        <Container>
          <Row className="justify-center">
            <Col md={6} className="col-12 text-center mb-[4.5rem]">
              <span className="font-serif mb-[10px] block uppercase text-md font-medium">
                Des Services et Solutions Adaptés à Vos Besoins
              </span>
              <h5 className="font-serif text-darkgray font-medium -tracking-[1px] mb-[15px]">
                Ce que nous faisons
              </h5>
            </Col>
          </Row>
          <Services
            grid="row-cols-1 row-cols-lg-3 row-cols-md-2 justify-center gap-y-10 sm:gap-y-[15px]"
            theme="service-style-02"
            className=""
            data={serviceData}
            animation={fadeIn}
          />
        </Container>
      </section>
      {/* Section Notre activités End */}

      {/* Section Start */}
      <section className="py-[130px] lg:py-[90px] md:py-[75px] xs:py-[50px]">
        <Container>
          <Row className="items-center justify-center">
            <Col
              xl={7}
              lg={8}
              md={7}
              sm={10}
              className="text-left sm:text-center sm:mb-[30px]"
            >
              <h5 className="font-serif text-darkgray font-medium mb-0">
                Avec{" "}
                <strong className="text-basecolor underline underline-offset-auto font-semibold">
                  plus de {new Date().getFullYear() - 2018} ans
                </strong>{" "}
                d'expérience, nous avons réalisé de nombreux projets au Maroc et
                à travers l'Afrique.
              </h5>
            </Col>
            <Col
              xl={{ span: 3, offset: 2 }}
              lg={4}
              md={5}
              className="sm:text-center text-right"
            >
              {/* Modal Component Start */}
              <CustomModal.Wrapper
                className="inline-block"
                modalBtn={
                  <span className="cursor-pointer font-serif inline-block py-[19px] px-[44px] text-white rounded about-us-bg-gradient">
                    <i className="fa fa-arrow-right line-icon-Video-5 text-[50px] inline-block ml-0 mr-[15px] align-middle"></i>
                    <div className="inline-block text-start text-md align-middle uppercase font-medium">
                      <span className="opacity-60 text-md block leading-[15px]">
                        Steellift
                      </span>
                      En Vidéo
                    </div>
                  </span>
                }
              >
                <div className="w-[1020px] max-w-full relative rounded mx-auto">
                  <div className="fit-video">
                    <iframe
                      width="100%"
                      height="100%"
                      className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                      controls
                      src="https://www.youtube.com/watch?v=04ghsj903rg"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </CustomModal.Wrapper>
              {/* Modal Component End */}
            </Col>
            <Col className="mt-[8.5rem] sm:mt-20">
              <Clients
                grid="row-cols-1 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 gap-y-10"
                theme="client-logo-style-01"
                data={ClientData01}
                animation={fadeIn}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <m.section
        className="py-[8%] xs:py-[50px] relative bg-cover overflow-hidden bg-fixed bg-no-repeat bg-center lg:bg-local"
        style={{
          backgroundImage:
            "url(/assets/img/steellift-energy-group-a-propos-contact-us.jpg)",
        }}
      >
        <div className="absolute h-full w-full opacity-75 top-0 left-0 bg-gradient-to-tr from-[#0039e3] via-[#5e28dd] to-[#8600d4]"></div>
        <Container>
          <Row className="row justify-center">
            <Col xl={7} lg={8} md={10} className="relative text-center">
              <h3 className="font-serif text-white font-semibold mb-[35px]">
                Discutons de votre projet
              </h3>
              <p className="text-white opacity-70 font-serif text-lg md:text-xmd md:leading-[22px] w-[80%] mx-auto leading-[32px] mb-[45px] sm:w-full">
                Notre équipe dévouée est prête à répondre à toutes vos questions
                et à vous guider dans vos démarches. Ne tardez pas, laissez-nous
                vous accompagner dès maintenant !
              </p>
              <Buttons
                to="/contantez-nous"
                className="btn-fill drop-shadow-md font-medium font-serif uppercase rounded-[50px] btn-shadow text-[15px] md:mb-[15px]"
                size="lg"
                themeColor="#fff"
                color="#000"
                title="Entrons en Contact"
              />
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Footer Section */}
      <DefaultFooter />
      {/* Footer End */}
    </div>
  );
};

export default AboutUsPage;

import React from "react";

// Libraries
import { Container, Row } from "react-bootstrap";
import { m } from "framer-motion";

// Components
import { fadeIn} from "../../Functions/GlobalAnimations";
import InteractiveBanners02 from "../../Components/InteractiveBanners/InteractiveBanners02";


// Data
import { InteractiveBannersData02 } from "../../Components/InteractiveBanners/InteractiveBannersData";

const OurSolutionSection = () => {
  return (
    <>
      <Container>
        <Row className="justify-center">
          <m.div
            className="col-xl-5 col-lg-6 col-md-8 col-sm-7 mb-20 text-center md:mb-[60px] sm:[44px]"
            {...fadeIn}
          >
            <span className="mb-[20px] font-medium text-md font-serif uppercase inline-block text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]">
              Nos Solutions
            </span>
            <h4 className="font-semibold -tracking-[1px] text-darkgray font-serif block heading-5">
              Solutions avancées pour ponts, portiques et potences de levage
            </h4>
          </m.div>
        </Row>
      </Container>
      <Container>
        <InteractiveBanners02
          carousalOption={{
            slidesPerView: 1,
            spaceBetween: 30,
            loop: true,
            autoplay: { delay: 5000, disableOnInteraction: false },
            breakpoints: {
              1200: { slidesPerView: 4 },
              992: { slidesPerView: 3 },
              768: { slidesPerView: 2 },
            },
          }}
          data={InteractiveBannersData02}
          animation={fadeIn}
          animationDelay={0}
        />
      </Container>
    </>
  );
};

export default OurSolutionSection;

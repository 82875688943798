// src/routesConfig.js
import HomePage from "./Pages/HomePage";
import AboutUsPage from "./Pages/AboutUsPage";
import OurSolutionsPage from "./Pages/OurSolutionsPage";
import OurServicesPage from "./Pages/OurServicesPage";
import ContactUsPage from "./Pages/ContactUsPage";
import NotFoundPage from "./Pages/404";
import OurIndustrialServicesPage from "./Pages/OurIndustrialServicesPage";
import IngineeringPage from "./Pages/IngineeringPage";
import EquipmentsPage from "./Pages/EquipmentsPage";
import PontRoulantPage from "./Pages/PontRoulantPage";
import PortiquePage from "./Pages/PortiquePage";
import PotencePage from "./Pages/PotencePage";
import CharpentePage from "./Pages/CharpentePage";
import TuyauteriePage from "./Pages/TuyauteriePage";
import ChauderoneriePage from "./Pages/ChauderoneriePage";
import ConvoyeurPage from "./Pages/ConvoyeurPage";
import UsinePbaPage from "./Pages/UsinePbaPage";
import HomeAfricaPage from "./Pages/HomeAfricaPage";
// Import other page components as needed

const routes = [
  { path: "/", element: <HomePage /> },
  { path: "/steel-lift-afrique", element: <HomeAfricaPage /> },
  { path: "/a-propos-de-nous", element: <AboutUsPage /> },
  { path: "/levage", element: <OurSolutionsPage /> },
  { path: "/nos-prestations-industrielles", element: <OurIndustrialServicesPage /> },
  { path: "/etudes-et-ingenierie", element: <IngineeringPage /> },
  { path: "/contactez-nous", element: <ContactUsPage /> },
  { path: "/levage/nos-services", element: <OurServicesPage /> },
  { path: "/levage/nos-equipements", element: <EquipmentsPage /> },
  { path: "/levage/nos-equipements/ponts-roulants", element: <PontRoulantPage /> },
  { path: "/levage/nos-equipements/portiques", element: <PortiquePage /> },
  { path: "/levage/nos-equipements/potences", element: <PotencePage /> },
  { path: "/nos-prestations-industrielles/charpente-et-menuiserie", element: <CharpentePage /> },
  { path: "/nos-prestations-industrielles/tuyauterie", element: <TuyauteriePage /> },
  { path: "/nos-prestations-industrielles/chaudronnerie", element: <ChauderoneriePage /> },
  { path: "/nos-prestations-industrielles/convoyeurs", element: <ConvoyeurPage /> },
  { path: "/nos-prestations-industrielles/usine-fabrication-pba", element: <UsinePbaPage /> },
  { path: "*", element: <NotFoundPage /> },
];

export default routes;

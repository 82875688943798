import React from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { m } from "framer-motion";

// Components
import { fadeIn } from "../Functions/GlobalAnimations";
import Buttons from "../Components/Button/Buttons";
import Clients from "../Components/Clients/Clients";
import Testimonials from "../Components/Testimonials/Testimonials";

// Partials
import EngineeringSection from "./Partial/EngineeringSection";
import DefaultFooter from "./Partial/DefaultFooter";
import DefaultHeader from "./Partial/DefaultHeader";
import OurSolutionSection from "./Partial/OurSolutionsSection";

// Data
import { TestimonialsData03 } from "../Components/Testimonials/TestimonialsData";
import InViewPort from "../Components/InViewPort";
import PrestationsSection from "./Partial/PrestationsSection";

const SwiperData = [
  {
    img: "/assets/img/jmti-morocco-crane-slide.jpg",
    title: "Structures au-Delà des Hauteurs",
    subtitle:
      "Ponts, Portiques et Potences de levage sur Mesure pour vos Besoins",
  },
];

const ClientData = [
  {
    img: "/assets/img/logos/ocp-logo.png",
  },
  {
    img: "/assets/img/logos/dlm-logo.png",
  },
  {
    img: "/assets/img/logos/cimaf-logo.png",
  },
  {
    img: "/assets/img/logos/jacob-logo.png",
  },
  {
    img: "/assets/img/logos/richbond-logo.png",
  },
];

const HomePage = (props) => {
  return (
    <div style={props.style}>
      <DefaultHeader />

      {/* Section Start */}
      <section className="full-screen md:h-[600px] sm:h-[500px]">
        <Swiper
          className="slider-nav-dark white-move swiper-pagination-03 swiper-pagination-light swiper-pagination-large h-full"
          modules={[Pagination, Autoplay]}
          slidesPerView={1}
          spaceBetween={0}
          loop={false}
          /*autoplay={{ delay: 6000, disableOnInteraction: false }}
          pagination={{ dynamicBullets: false, clickable: true }}*/
        >
          {SwiperData.map((item, i) => {
            return (
              <SwiperSlide
                key={i}
                style={{ backgroundImage: `url(${item.img})` }}
                className="bg-no-repeat	bg-cover	overflow-hidden relative bg-center"
              >
                <div className="absolute h-full w-full top-0 left-0 bg-gradient-dark-slate-blue"></div>
                <Container className="h-full">
                  <Row className="h-full">
                    <Col
                      xl={6}
                      lg={7}
                      sm={8}
                      xs={12}
                      className="h-full text-white font-serif justify-center flex-col relative flex"
                    >
                      <p className="mb-[40px] font-light text-xmd opacity-70 xs:mb-[20px]">
                        {item.subtitle}
                      </p>
                      <h2 className="mb-[55px] font-semibold xs:mb-[35px]">
                        {item.title}
                      </h2>
                      <div className="inline-block">
                        <Buttons
                          href="/levage"
                          target="_blank"
                          className="btn-fancy text-xs tracking-[1px] font-serif font-medium rounded-none py-[12px] mr-[30px] uppercase md:mb-[15px] sm:mb-0"
                          themeColor={["#FF9900", "#663300"]}
                          size="md"
                          color="#fff"
                          title="En savoir plus"
                        />
                        <Buttons
                          to="/contactez-nous"
                          size="md"
                          className="!pt-0 px-0 pb-[2px] relative border-b-[2px] bg-transparent m-auto after:h-[2px] after:bg-white font-serif text-[13px] tracking-[0.5px] hover:text-white uppercase btn-link md:mb-[15px] mb-[15px]"
                          color="#fff"
                          title="Contactez-nous"
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[130px] lg:py-[90px] home-business-piechart md:py-[75px] sm:[50px]">
        <Container>
          <Row className="justify-center md:block">
            <Col
              lg={5}
              sm={9}
              className="text-left flex-col items-start flex md:text-center md:my-0 md:mx-auto md:mb-[70px] md:items-center sm:mb-[65px]"
            >
              <div className="mb-[20px]">
                <span className="inline-block font-serif text-md font-medium uppercase text-gradient bg-gradient-to-r from-[#556fff] via-[#e05fc4] to-[#ff798e]">
                  A Propos de nous
                </span>
              </div>
              <h5 className="w-[90%] mb-[20px] font-semibold text-darkgray font-serif sm:mb-[30px] xxs:w-full">
                Construisez les fondations de l'avenir avec nos solutions
              </h5>
              <Buttons
                href="/a-propos-de-nous/"
                className="font-medium font-serif uppercase bg-[#fff]  text-[#000] hover:bg-black rounded-none md:mb-[15px] text-xxs btn-fancy xs:mb-0"
                color="#000"
                size="sm"
                themeColor="#000"
                title="En Savoir Plus"
              />
            </Col>
            <Col lg={{ offset: 1 }}>
              <m.div {...{ ...fadeIn, transition: { delay: 0.6 } }}>
                <p className="w-[84%] mb-[25px] block lg:w-full">
                  Chez <b>Steel Lift Energy Group</b>, nous sommes un leader
                  dans le domaine du levage industriel, de l'ingénierie, et de
                  la fabrication métallique. Forts de plusieurs années
                  d'expérience, nous nous engageons à fournir des solutions
                  innovantes et sur mesure pour répondre aux besoins spécifiques
                  de nos clients.
                  <br />
                  Avec un engagement constant envers l'excellence, nous nous
                  efforçons de devenir votre partenaire de confiance pour tous
                  vos projets industriels.
                </p>
              </m.div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Lazy Load HTML */}
      <InViewPort>
        {/* Section Nos Solutions */}
        <section className="py-[130px] lg:py-[90px] md:py-[75px] sm:[50px] bg-[#f7f8fc] overflow-hidden">
          <OurSolutionSection />
        </section>
        {/* Section End */}
      </InViewPort>

      {/* Section Start */}
      <section className="py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
        <EngineeringSection />
      </section>
      {/* Section End */}

      {/* Section Start */}
      <m.section
        className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] bg-lightgray overflow-hidden"
        {...fadeIn}
      >
        <PrestationsSection />
      </m.section>
      {/* Section End */}

      <hr />

      {/* Section Start */}
      {/*
      <section className="py-[130px] lg:py-[90px] md:py-[75px] sm:[50px]">
        <Container>
          <Row className="justify-center">
            <m.div
              className="col-xl-5 col-lg-6 col-sm-8 mb-20 text-center"
              {...fadeIn}
            >
              <span className="inline-block font-serif text-md uppercase mb-[20px] font-medium  text-gradient bg-gradient-to-r from-[#556fff] via-[#e355ff] to-[#ff798e]">
                Témoignages Précieux
              </span>
              <h3 className="w-full mb-[20px] font-semibold text-darkgray font-serif heading-5">
                Laissez-vous inspirer par des témoignages authentiques
              </h3>
            </m.div>
          </Row>
          <Row className="sm:justify-center sm:flex">
            <Col md={12} sm={8}>
              <Testimonials
                grid="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-sm-8 gap-y-10 justify-center"
                theme="testimonials-style-03"
                data={TestimonialsData03}
                animation={fadeIn}
              />
            </Col>
          </Row>
        </Container>
      </section>
      */}
      {/* Section End */}
      <hr />
      {/* Section Start */}
      <section className="bg-[#f7f8fc] py-[100px] lg:py-[90px] md:py-[75px]">
        <Container>
          <Row className="justify-center">
            <Col lg={11}>
              <Clients
                grid="row-cols-2 row-cols-lg-6 row-cols-sm-3 md:gap-10 xs:gap-y-[50px] justify-between md:justify-center"
                theme="client-logo-style-05"
                data={ClientData}
                animation={fadeIn}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Footer Section */}
      <DefaultFooter />
      {/* Footer End */}
    </div>
  );
};

export default HomePage;
